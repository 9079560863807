import { postJSON } from '.';

export const trackGlobalEvent = (eventName, eventProperties) => {
  postJSON('/api/webapp/track/global/event/', {
    name: eventName,
    properties: eventProperties,
  });
};

export const trackClientEvent = (clientSlug, eventName, eventProperties) => {
  postJSON('/api/webapp/track/client/event/', {
    client_slug: clientSlug,
    name: eventName,
    properties: eventProperties,
  });
};

export const trackAccountEvent = (accountSlug, eventName, eventProperties) => {
  postJSON('/api/webapp/track/account/event/', {
    account_slug: accountSlug,
    name: eventName,
    properties: eventProperties,
  });
};

export const trackAccountPageView = (
  accountSlug,
  pageName,
  referrerCategory,
) => {
  postJSON('/api/webapp/track/account/pageview/', {
    account_slug: accountSlug,
    name: pageName,
    properties: {
      url: window.location.href,
      path: window.location.pathname,
      referrer: document.referrer,
      referrer_category: referrerCategory,
    },
  });
};
