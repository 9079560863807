import React from 'react';

type RadioButtonsOptions = {
  name: string;
  value: string;
};

type RadioButtonsProps = {
  selectedValue: string;
  label: string;
  options: RadioButtonsOptions[];
  onChangeFunction: any;
  onChangeParameters: any[];
};

const RadioButtons = ({
  selectedValue,
  label,
  options,
  onChangeFunction,
  onChangeParameters,
}: RadioButtonsProps) => (
  <fieldset className="mt-6">
    <legend className="text-sm font-medium leading-5 text-gray-700">
      {label}
    </legend>
    <div className="m-2">
      {options.map((option, index) => (
        <div className="flex items-center" key={index}>
          <input
            id={option.value}
            value={option.value}
            onChange={(e) => {
              onChangeFunction(...onChangeParameters, e.target.value);
            }}
            checked={selectedValue === option.value}
            type="radio"
            className="form-radio transition duration-150 ease-in-out"
          />
          <label htmlFor={option.value} className="ml-3">
            <span className="block text-sm leading-5 font-medium text-gray-700">
              {option.name}
            </span>
          </label>
        </div>
      ))}
    </div>
  </fieldset>
);

export default RadioButtons;
